import './instructions.css';

function Instructions(){
    return(
        <>
            <h2>Instrukcja:</h2>
            <ol>
                <li> 
                    Wybierz poziom wkurwienia
                </li>
                <li>
                    Wpisz tekst
                </li>
                <li>
                    Kliknij przycisk
                </li>
                <li>
                    Ciesz się wynikiem 
                </li>
            </ol>
        </>
    )
}

export default Instructions;