import { Component } from "react";
import './header.css'

export default class Header extends Component
 {
    render(){
        return(
            <>
                <div className="header">
                    <h1>Kurwafikator</h1>
                    <hr />
                </div> 
            </>
        )
    }
  }

